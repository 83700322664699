/**
 * App Header
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Avatar_02, Avatar_03, Avatar_05, Avatar_06, Avatar_08, Avatar_09, Avatar_13, Avatar_17, Avatar_21, headerlogo, loginLogo, lnEnglish, lnFrench, lnGerman, lnSpanish } from '../../Entryfile/imagepath';
import { getUserName, getUserType, getProfilePicture, getTitle, getLogo, getReadableDate, getTokenCookie, verifyOrgLevelViewPermission } from '../../utility';
import { getHeaderNotifications } from '../service';
import { withRouter } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { verifyViewPermission,verifyViewPermissionForTeam,getRoleName,getEmployeeId } from '../../utility';

const employeeRoleName = getRoleName();

class SideNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: getUserName(),
            title: getTitle(),
            logo: getLogo(),
            notifications: [],
            showNavbarIs: true,
            showdropId: localStorage.getItem('showdropId') || '',
            subMenuId: localStorage.getItem('subMenuId') || '',
            showSearchBar: false,
            roleValidationArray: []
        }

    }
    componentDidMount() {
        let {roleValidationArray} = this.state;
        (this.props.menu).map((res) => {
            if(verifyViewPermission(res.name)){
            // if(verifyViewPermissionForTeam(res.name)){

                roleValidationArray.push(res.name);
            }
        })

        this.fetchNotificationList();
    }
    fetchNotificationList = () => {
        getHeaderNotifications().then((res) => {
            if (res.status === "OK") {
                this.setState({ notifications: res.data });
            }
        });
    }
    dropDownShow = (ids) => {
        this.setState({ showdropId: ids })
        localStorage.setItem('showdropId', ids);
    }
    handleSubmenuId = (ids) => {
        this.setState({ subMenuId: ids })
        localStorage.setItem('subMenuId', ids);
    }
    handleLinkClick = (item) => {
        const subMenu = item.subMenu.sort((a, b) => a.sortOrder - b.sortOrder)
        const subItemUrl = subMenu[0].url
        this.setState({ subMenuId: subMenu[0].id })
        localStorage.setItem('subMenuId', subMenu[0].id);
        this.props.history.push(`/app/company-app${subItemUrl}`);

    };

    // handleSearch = (e) => {
    //     console.log(e.target.value)
    // }

    toggleSearchBar = () => {
        this.setState((prevState) => ({
            showSearchBar: !prevState.showSearchBar,
        }));
    };

    toggleSearchBar = () => {
        this.setState({ showSearchBar: !this.state.showSearchBar })
    };

    render() {
        const { location } = this.props
        let isEMPLOYEE = getUserType() == 'EMPLOYEE';
        const { logo, notifications, title, userName, showSearchBar } = this.state;
        let pathname = "location.pathname"
        let isSuperAdmin = getUserType() == 'SUPER_ADMIN';
        let isCompanyAdmin = getUserType() == 'COMPANY_ADMIN';
        const style = { color: "white", fontSize: "26px" }
        let EmployeeValidation = getEmployeeId();
        return (
            <>
                {/* <div className="search-bar-container">
                    <input
                        type="text"
                        className={`search-bar ${showSearchBar ? "expanded form-control" : ""}`}
                        placeholder="Search"
                        aria-label="Search"
                        onChange={(e) => {this.handleSearch(e)}}
                    />
                    <div onClick={this.toggleSearchBar} className='searchIconView'>
                        {showSearchBar ? <RxCross2 style={style} /> : <FiSearch style={style} />}

                    </div>
                </div> */}

                <div className="sideNavHeader" >
                    <ul className="navbar-nav mainMenu w-100 justify-content-evenly align-items-center">
                        {this.props.menu && this.props.menu.sort((a, b) => a.sortOrder - b.sortOrder).map((item, index) => {
                            const removeWord = ["mr-2"];
                            let appUrl = isSuperAdmin ? 'admin-app' : 'company-app';
                            let isCurrentUrl = pathname === `/app/${appUrl}${item.url}`;
                            let isSubMenuPresent = (item.subMenu && item.subMenu.length > 0) || item.url == "#";
                            let menuIcon = item.icon.split(/\s+/).filter(word => !removeWord.includes(word)).join(' ')

                            return (
                                <div key={index}>
                                  { (this.state.roleValidationArray).includes(item.name) && <div>
                                    {isSubMenuPresent ?
                                        <div>
                                            <a onClick={() => { this.dropDownShow(item.id); this.handleLinkClick(item) }} style={{ color: 'white', width: '160px' }}
                                                className={`${parseInt(this.state.showdropId) === item.id ? 'menuBackgroud' : ''} p-2 mr-3 sideNavMenu nav-link`} >
                                                <em className={menuIcon}></em> <span className='ml-2 '>{item.name}</span> </a>

                                        </div> :
                                        <Link to={item.url != '/' ? `/app/${appUrl}${item.url}` : '/app/main/dashboard'} target={item.target} id='navText' className='text-white'>
                                            <a onClick={() => this.dropDownShow(item.id)} style={{ color: 'white', width: '160px' }}
                                                className={`${this.state.showdropId === item.id ? 'menuBackgroud' : ''} p-2 mr-3 sideNavMenu nav-link`} >
                                                <em className={menuIcon}></em> <span className='ml-2 '>{item.name}</span>
                                            </a>
                                        </Link>
                                    }
                                    {!showSearchBar ? <div className='navSubMenu'>
                                        {item.id === parseInt(this.state.showdropId) ?
                                            item.subMenu.sort((a, b) => a.sortOrder - b.sortOrder).map((subItem, subIndex) => {
                                                let isCurrentSubUrl = pathname === `/app/${appUrl}${subItem.url}`;
                                                let isSubSubMenuPresent = (subItem.subMenu && subItem.subMenu.length > 0) || subItem.url == "#";
                                                if(subItem.name === "My Team" && !verifyViewPermission ("Peoples My Team")){
                                                   return null;
                                                }
                                                if(subItem.name === "Organization" && !verifyViewPermission ("Peoples Organization")){
                                                    return null;
                                                 }
                                                return (
                                                    <ul className='navSubMenu_items'>
                                                        {(subItem.name != "Approvals" ||  verifyViewPermission("Approvals")) && (subItem.name != "My Team" || EmployeeValidation > 0) && (subItem.name != "My Profile" || EmployeeValidation > 0) &&
                                                        <li className={isSubSubMenuPresent ? 'openSubMenu' : ''} key={index + "" + subIndex}>
                                                            {
                                                                <Link to={`/app/${appUrl}${subItem.url}`} target={item.target} >
                                                                    <span onClick={() => this.handleSubmenuId(subItem.id)} className={`${parseInt(this.state.subMenuId) === subItem.id ? 'subMenuBackground' : ''} subMenuName small`}>{subItem.name}</span>
                                                                </Link>

                                                            }

                                                        </li>}
                                                    </ul>
                                                )
                                            })
                                            : null}

                                    </div> : null}
                                    </div>}
                                </div>
                            )

                        })}

                    </ul>


                </div>


            </>
        );
    }
}
export default withRouter(SideNavbar);